.dot-flashing {
  margin: 0px 0px 0px 24px;
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000;
  color: #000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #000;
  }
  50%,
  100% {
    background-color: #ffffff;
  }
}

.stroke-text{
  -webkit-text-stroke:1px black;
}
